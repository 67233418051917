import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

import { Browser } from '@capacitor/browser';

@Injectable({
    providedIn: 'root'
})
export class CapacitorUtilsService {

    static readonly isApp = Capacitor.getPlatform() !== 'web'; // Variable para determinar si la plataforma es movil o no, y regresa true or false. 
    static readonly wichPlatform = Capacitor.getPlatform(); // Variable para determinar que plataforma esta ejecutando la app.

    constructor() { }

    /* static async writeAndOpenFile(data: Blob, fileName: string) {
        var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = async function () {
            var base64data = reader.result;
            try {
                const result = await Filesystem.writeFile({
                    path: fileName,
                    data: <string>base64data,
                    directory: Directory.Data,
                    recursive: true
                });

                let fileOpener: FileOpener = new FileOpener();
                fileOpener.open(result.uri, data.type)
                    .then(() => console.log('File is opened'))
                    .catch(e => console.log('Error opening file', e));

                console.log('Wrote file', result.uri);
            } catch (e) {
                console.error('Unable to write file', e);
            }
        }

    } */

    /**
   * @name goToLaunchBrowser
   * @desc Methodo que se encarga de abrir el navegador del dispositivo
   * @webUrl Parametro que recibe es la url a abrir dentro del navegador
   */
    async goToLaunchBrowser(webUrl: string) {
        const retx = await Browser.open({ url: webUrl });
        // console.log('Open url response: ', retx);
    }
    
    /**
   * @name goToCloseLaunchBrowser
   * @desc Methodo que se encarga de cerrar el navegador del dispositivo
   * @webUrl Parametro que recibe es la url a abrir dentro del navegador
   */
    async goToCloseLaunchBrowser() {
        await Browser.close();
        // console.log('Open url response: ', retx);
    }

    // Helper functions
    public convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader;
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        }
        reader.readAsDataURL(blob);
    });

    public getMinetype(name: string) {
        if (name.indexOf('xlsx')) {
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        } else if (name.indexOf('pdf')) {
            return 'application/pdf';
        } else {
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        }
    }
}
