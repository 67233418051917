import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { Cookie } from 'ng2-cookies/ng2-cookies';

import { get } from '../common/app-storage.service';
import { ApiProvider } from '../common/api.service';
import { apiURL, apiEndpoints } from './../../common/constants';
import { apiCivarLogin } from '../../common/constants';

import { appVersionInfo } from '../../interfaces/version.interface';

@Injectable({
  providedIn: 'root'
})
export class LoginProvider {

  token: string;


  constructor(private api: ApiProvider, private alertCtrl: AlertController) {
  }

  /**
   * @name login
   * @desc Método para mandar credenciales a API y validarlas.
   * @param {Object} credentials viene la info de inicio de sesión
   * @returns {Object} - Promise
   */
  login(credentials: any, versionApp: string, platformApp: string) {
    const records = {
      Login: {
        username: credentials.username,
        password: credentials.password,
        // appVersion: versionApp,
        // appPlatform: platformApp
      }
    };

    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.login;
      this.api.post(endpoint, records).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject(data.ErrorMessage);
          } else {
            resolve(data.Token);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.status + " " + error.statusText);
        }
      )
    });
  }

  saveToken(token: string) {
    const records = {
      NotificationToken: token
    };
    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.NotificationRegister;
      this.api.post(endpoint, records, apiURL).subscribe(
        (data: any) => {
          console.log('saveToken: ', JSON.stringify(data), ' - token: ', records);

          if (data.ErrorMessage) {
            reject(data.ErrorMessage);
          } else {
            resolve(data.Token);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.status + " " + error.statusText);
        }
      )
    });
  }

  /**
   * @name logout
   * @desc Método que hace un request post para cerrar la sesion del usuario
   * @returns {Object} Observable
   */
  logout() {
    const endpoint = apiEndpoints.logout;
    return this.api.post(endpoint);
  }

  /**
   * @name promptLogout
   * @desc Método que muestra un mensaje de confirmación cuando se presiona el boton de Salir del menu
   */
  async promptLogout() {
    return new Promise(async (resolve) => {
      const alert = await this.alertCtrl.create ({
        header: 'Salir',
        message: '¿Está seguro de querer cerrar sesión?',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {
              resolve(false);
            }
          },
          {
            text: 'Cerrar sesión',
            handler: () => {
              this.logout().subscribe(
                (data: any) => {
                  resolve(true);
                },
                (error: HttpErrorResponse) => {
                  resolve(true);
                }
              );
            }
          }
        ],
        cssClass: 'base-alert'
      });

      await alert.present();
    });
  }

  loginCivar(token) {
    return new Promise<void>((resolve, reject) => {
      const url = apiCivarLogin + token['Access_Token']

      this.api.post('', '', url, false).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject(data.ErrorMessage);
          } else {
            resolve();
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.status + " " + error.statusText);
        }
      )
    });
  }

  setCredentialsCookie(u: string, p: string): void {
    // Cookie.set("__csi_credentials", u + " " + p, 365);
  }

  async hasCredentialsCookie(): Promise<boolean> {
    const credentials = await get('credentials');
    if(credentials) return true;
    else return false;
    // return document.cookie.indexOf("__csi_credentials") > -1;
  }

  async getCredentialsCookie(): Promise<string[]> {
    // let cookie = Cookie.get("__csi_credentials");
    let credentials = await get('credentials');
    if (credentials) {
      return credentials;
    }
    return null;
  }

  /**
   * @name versionSupported
   * @desc Método para mandar plataforma y version de la App y validar si es un version soportada.
   * @param {Object} appInfo viene la info de la version del App
   * @returns {Object} - Promise
   */
  versionSupported(appInfo: appVersionInfo) {
    const records = {
      Version: {
        appVersion: appInfo.appVersion,
        appPlatform: appInfo.appPlatform
      }
    };

    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.hasSupport;
      this.api.post(endpoint, records).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject(data.ErrorMessage);
          } else {
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.status + " " + error.statusText);
        }
      )
    });
  }


  /**
   * @name validateEmailUser
   * @desc Método para mandar plataforma y version de la App y validar si es un version soportada.
   * @param {Object} appInfo viene la info de la version del App
   * @returns {Object} - Promise
   */
  validateEmailUser(credentials: any) {
    const records = {
      Login: {
        username: credentials.username,
      }
    };

    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.userLogin;
      this.api.post(endpoint, records).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject(data.ErrorMessage);
          } else {
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.status + " " + error.statusText);
        }
      )
    });
  }
  
  /**
   * @name goSSOLogin
   * @desc Método para mandar plataforma y version de la App y validar si es un version soportada.
   * @param {Object} appInfo viene la info de la version del App
   * @returns {Object} - Promise
   */
  goSSOLogin(credentials: any) {
    const records = {
      Login: {
        username: credentials.username,
        password: credentials.password,
        // appVersion: versionApp,
        // appPlatform: platformApp
      }
    };

    return new Promise((resolve, reject) => {
      const endpoint = apiEndpoints.SSOLogin;
      this.api.post(endpoint, records).subscribe(
        (data: any) => {
          if (data.ErrorMessage) {
            reject(data.ErrorMessage);
          } else {
            resolve(data);
          }
        },
        (error: HttpErrorResponse) => {
          reject(error.status + " " + error.statusText);
        }
      )
    });
  }

}
