import { Component, ViewChild, OnInit, NgZone } from '@angular/core';
import { NavController, Platform, AlertController, IonRouterOutlet, ModalController } from '@ionic/angular';
import { Router, RouterEvent, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

import { App, URLOpenListenerEvent } from '@capacitor/app';

import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { TranslateService } from '@ngx-translate/core';

// declare var zip:any;
import { appVersionInfo, appVersionResponse } from './interfaces/version.interface';

import { DataService } from './providers/common/data.service';
import { Events } from './providers/common/events.service';
import { URLService } from './providers/common/url.service';
import { CapacitorUtilsService } from './providers/common/capacitorUtils.service';

import { UserProvider } from './providers/user/user.service';
import { LoginProvider } from './providers/login/login.service';
import { SessionProvider } from './providers/session/session.service';
import { AppStorageProvider, set } from './providers/common/app-storage.service';
import { AppVersionCheckService } from './providers/common/appVersionCheck.service';
import { FavoriteHomeProvider } from './providers/favorite-home/favorite-home-service.service';


import { SplashScreenModal } from './components/modal-splash-screen/modal-splash-screen.component';
import { UtilityFunctionService } from './providers/common/utilityFunction.service';

import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class CsiApp implements OnInit {
  @ViewChild(IonRouterOutlet, { static: true })
  routerOutlet: IonRouterOutlet;
  backButtonSubscription;

  previousUrl: string = null;
  currentUrl: string = null;
  @ViewChild(NavController) nav: NavController;
  rootPage: any;

  //menu configurable
  showTrackingMap: boolean = false;
  showTravelList: boolean = false;
  showScore: boolean = false;
  showClaims: boolean = false;
  showConfig: boolean = true;
  //showConsultaCD: boolean = false
  showReport: boolean = false;
  showSingleQueryVin: boolean = false;
  showHome: boolean = true;

  showMultipleVinConsult: boolean = true;
  showConsultByDealer: boolean = true;
  showConsultBySite: boolean = false;

  showExchange: boolean = false;
  showShipmentRequest: boolean = false;

  showMatrizEstatusEquipo: boolean = false;
  showProgramacionCarga: boolean = false;

  showAlertasPage: boolean = false;
  showConsultaDeTractor: boolean = false;

  showInvestorRelations: boolean = false;

  showRateTrip: boolean = false;

  goToRating = '';
  ratingsParam = '';
  isAuth: boolean = false;

  showedAlert: boolean;
  confirmAlert: any;
  public activeMenuItem: string = "";
  public civarMenu: any[] = [];
  showMenuLatDesktop: boolean = true;

  isOutSplashScreen: boolean = true;

  appStoreName: string = '';

  constructor(
    private router: Router, private dataService: DataService,
    private platform: Platform, private urlService: URLService,
    private sessionProvider: SessionProvider, private loginProvider: LoginProvider,
    private userProvider: UserProvider, private storage: AppStorageProvider,
    public alertCtrl: AlertController, private events: Events,
    private modalCtrl: ModalController, private translate: TranslateService,
    private versionCheckServ: AppVersionCheckService, private favHomeServ: FavoriteHomeProvider,
    private utilityService: UtilityFunctionService, private location: Location,
    private zone: NgZone, private capacitorService: CapacitorUtilsService
  ) {
    try {
      this.initializeApp();
    }
    catch (e) {
      this.msgError(e);
    }
  }

  initializeApp() {
    console.log("App initialize!");
    this.translate.setDefaultLang('es');

    this.platform.ready().then(async () => {
      this.events.subscribe("app:logout", (val) => {
        // Logout without promt (sesion expirada)
        this.goToLoginPage();
        this.sessionProvider.resetAll();
      });
      this.events.subscribe("app:login", () => {
        this.isAuth = true;
        this.getCivarMenu();
      });
      this.events.subscribe("menulat:togglevisibility", () => {
        this.showMenuLatDesktop = !this.showMenuLatDesktop;
      });
      this.router.events.subscribe((event: RouterEvent) => {
        let currentRoute = this.router.url;
        this.getActiveMenuItem(currentRoute);
        this.events.publish("nav:active", this.activeMenuItem);
      });
      if (this.platform.is('cordova')) {
        this.isOutSplashScreen = false;

        if (this.platform.is('ios')) {
          await StatusBar.setStyle({ style: Style.Dark });
        }

        if (this.platform.is('android') || this.platform.is('ios')) {
          await SplashScreen.hide();

          const modal = await this.modalCtrl.create({
            component: SplashScreenModal,
            cssClass: 'fullscreen',
            mode: 'md'
          });
          modal.onDidDismiss().then(() => {
            this.isOutSplashScreen = true;
          });
          return await modal.present();
        }

        /* if (this.platform.is('android')) {
          this.androidPermissions.requestPermissions([
            this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE,
            this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE
          ]);
        } */

      }

      // let expirationJob = () => {
      //   this.rootPage = LoginPage;
      // }

      // this.sessionProvider.expirationNotifier.subscribe(expirationJob);

    });
    this.backButtonSubscription = this.platform.backButton.subscribe(() => {
      const stackCtrl = (this.routerOutlet as any).stackCtrl;
      const stackId = this.routerOutlet.getActiveStackId();
      const depth = stackCtrl.getStack(stackId).length;
      if (depth == 1) {
        if (!this.showedAlert) {
          this.confirmExitApp();
        } else {
          this.showedAlert = false;
          this.confirmAlert.dismiss();
        }
      }

      this.nav.pop();
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const codeToken = event.url.split("redirect?code=").pop();
        const logoutURI = event.url.split("dev.quantum.csigroup.com.mx").pop();
        console.log('--> URL: ', codeToken);
        console.log('--> logoutURI: ', logoutURI);

        if (codeToken) {

          const routeParams = { // Se genera un objeto con los parametros a transmitir a la siguiente route
            routeId: this.utilityService.randomNum(),
            code: codeToken,
          };

          this.capacitorService.goToCloseLaunchBrowser();

          this.dataService.setData(routeParams.routeId, routeParams); // Con ayuda del provider DataService asignamos el objeto parametros a un iD, para de esta manera poder recibirlo en la siguiente ruta/pantalla
          this.router.navigate(['/login/redirect/' + routeParams.routeId], { replaceUrl: true });
        }

        // App version oAuth logout redirect catch function
        if (logoutURI.includes('/login/logout')) {
          console.log('logout redirect call')
          this.sessionProvider.resetAll();

          setTimeout(() => {
            this.goToLoginPage();
            // this.router.navigate(['/login'], { replaceUrl: true });
          }, 1500);
        }
      });
    });

    // Web version oAuth logout redirect catch function

    let currentPath: string = this.location.path();

    if (currentPath.includes('/login/logout')) {
      console.log('logout redirect call')
      this.sessionProvider.resetAll();

      setTimeout(() => {
        this.goToLoginPage();
        // this.router.navigate(['/login'], { replaceUrl: true });
      }, 1500);
    }

    // Funcion para determinar y guardar el tema en base al sistema del usuario.
    this.loadThemeApp();
  }

  async loadThemeApp() {

    this.storage.get('themeDark:active').then((isEnabled: boolean) => {
      console.log('Storage, themeDark:active: ', isEnabled);

      if (!this.utilityService.isEmpty(isEnabled)) {

        this.events.publish('themeDark:active', isEnabled);
        this.storage.put('themeDark:active', isEnabled);

        document.body.classList.toggle('dark', isEnabled);
      } else {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        console.log('System, themeDark:active: ', prefersDark);


        this.events.publish('themeDark:active', prefersDark);
        this.storage.put('themeDark:active', prefersDark);

        document.body.classList.toggle('dark', prefersDark);
      }

    });
  }

  async onLoadValidateVersionApp() {
    if (CapacitorUtilsService.isApp) {
      this.appStoreName = CapacitorUtilsService.wichPlatform === 'ios' ? 'App Store' : 'Play Store';

      let appInfo: appVersionInfo = await this.versionCheckServ.getObjectVersionInfo();
      console.log(appInfo);

      this.loginProvider.versionSupported(appInfo).then((result: appVersionResponse) => {
        console.log('Response: ', result);
        if (!result.SupportedVersion) {

          setTimeout(() => {
            this.alertCtrl.dismiss();
            this.updateAppRequired();
          }, 4000);
        }
      });
    }
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.urlService.setPreviousUrl(this.previousUrl);
    });

    this.events.subscribe("homePage:Custom", (idPage: number) => {
      // console.log('Redirect page id: ', idPage);
      this.onTypeEmitted(idPage);
    });

    this.sessionProvider.load().then(() => {
      if (this.sessionProvider.isAuthenticated()) {
        this.userProvider.getMenu().then((data: any) => {
          if (data.ErrorMessage != '') {
            console.log('Error existe ')
            setTimeout(() => {
              this.alertCtrl.dismiss()
            }, 5000);
          }

          if (data) {
            this.events.publish('menuLoad', new Date());
            if (data.UserProfile != 1 && data.UserProfile) {
              this.isAuth = true;
              //this.showConsultaCD = true;
              this.showConsultBySite = true;
              this.showConfig = true;
              this.showHome = false;
              // this.router.navigate(['/consulta/by_site_mexico'], { replaceUrl: true });
            } else if (data.Preferencias) {
              this.isAuth = true;
              this.showHome = true;
              for (const item of data.Preferencias) {
                switch (item.Id_Preferencia) {
                  case '1':
                    this.showTrackingMap = true;
                    break;
                  case '2':
                    //notifications center
                    break;
                  case '3':
                    this.showTravelList = true;
                    break;
                  case '4':
                    this.showScore = true;
                    break;
                  case '5':
                    this.showClaims = true;
                    break;
                  case '6':
                    this.showConfig = true;
                    break;
                  case '7':
                    //help
                    break;
                  case '8':
                    this.showSingleQueryVin = true;
                    break;
                  case '9':
                    this.showConsultBySite = true;
                    break;
                  case '10':
                    this.showReport = true;
                    break;
                  case '11':
                    this.showExchange = true;
                    break;
                  case '12':
                    this.showMultipleVinConsult = true;
                    break;
                  case '13':
                    this.showConsultByDealer = true;
                    break;
                  case '15':
                    this.showMatrizEstatusEquipo = true;
                    break;
                  case '16':
                    this.showProgramacionCarga = true;
                    break;
                  case '17':
                    this.showAlertasPage = true;
                    break;
                  case '20':
                    this.showShipmentRequest = true;
                    break;
                  case '21':
                    this.showConsultaDeTractor = true;
                    break;
                  case '22':
                    this.showInvestorRelations = true;
                    break;
                  case '24':
                    this.showRateTrip = true;
                    break;
                  default:
                    break;
                }
              }
              // this.router.navigate(['/home'], { replaceUrl: true });
            } else {
              this.goToLoginPage();
            }
          }
          // else {
          //   this.rootPage = LoginPage;
          // }
        });
        this.getCivarMenu();
        console.log('---> favHome: ', this.favHomeServ.getFavorite());
      } else {
        this.goToLoginPage();
      }
    });
  }

  async goToLoginPage() {
    this.isAuth = false;

    let currentPath: string = this.location.path();
    if (currentPath.includes('/login/redirect?code=')) {
      let userToken = currentPath.replace('/login/redirect?code=', '');

      const routeParams = { // Se genera un objeto con los parametros a transmitir a la siguiente route
        routeId: this.utilityService.randomNum(),
        code: userToken,
      };
      // console.log('params: ', routeParams);

      this.dataService.setData(routeParams.routeId, routeParams); // Con ayuda del provider DataService asignamos el objeto parametros a un iD, para de esta manera poder recibirlo en la siguiente ruta/pantalla
      this.router.navigate(['/login/redirect/' + routeParams.routeId], { replaceUrl: true });

    } else {
      this.router.navigate(['/login'], { replaceUrl: true });
    }
  }

  ionViewDidEnter() {
    /* this.router.events.subscribe((event: RouterEvent) => {
      let currentRoute = this.router.url;
      this.getActiveMenuItem(currentRoute);
      this.events.publish("nav:active", this.activeMenuItem);
    }); */
  }

  ngAfterViewInit() {
    // this.onLoadValidateVersionApp();
  }

  ngOnDestroy() {
    this.backButtonSubscription.unsubscribe();
  }

  getCivarMenu() {
    this.userProvider.getCivarMenu().then((data: any) => {
      this.civarMenu = data;
      this.events.publish("civarMenu", data);
    }).catch(error => {
      console.error(error);
    });
  }

  async confirmExitApp() {
    this.showedAlert = true;
    const confirmAlert = await this.alertCtrl.create({
      header: 'Salir',
      message: '¿ Esta seguro que desea salir de la aplicación ?',
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {
            this.showedAlert = false;
            return;
          }
        },
        {
          text: 'Aceptar',
          handler: () => {
            navigator['app'].exitApp();
          }
        }
      ],
      cssClass: 'base-alert'
    });
    await confirmAlert.present();
  }

  async msgError(error) {
    this.showedAlert = true;
    const confirmAlert = await this.alertCtrl.create({
      header: 'Prueba',
      message: error,
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {
            this.showedAlert = false;
            return;
          }
        },
        {
          text: 'Aceptar',
          handler: () => {
            navigator['app'].exitApp();
          }
        }
      ],
      cssClass: 'base-alert'
    });
    await confirmAlert.present();
  }

  onTypeEmitted(navControl: any) {
    switch (navControl) {
      case -1:
        // this.goToHome("financiero");
        this.goToDashboardFinanciero();
        break;
      case -2:
        // this.goToHome("operativo");
        this.goToDashboardOperativo();
        break;
      case -3:
        this.goToDashboardAMDF();
        break;
      case -4:
        this.goToDashboardMazda();
        break;
      case -5:
        this.goToDashboardBMW();
        break;
      case -10:
        this.goToDashboardBSCCPVPage();
        break;
      case -11:
        this.goToDashboardBSCCSIPage();
        break;
      case -12:
        this.goToDashboardBSCCSIDPage();
        break;
      case -13:
        this.goToDashboardBSCCSIXPage();
        break;
      case -14:
        this.goToDashboardBSCSunusPage();
        break;
      case 1:
        this.goToHome();
        break;
      case 2:
        this.goToTrackingMap();
        break;
      case 3:
        this.goToTravelList();
        break;
      case 4:
        this.goToScore();
        break;
      case 5:
        this.goToClaims();
        break;
      case 6:
        this.goToConfig();
        break;
      case 7:
        break;
      case 8:
        this.goToSingleQueryVin();
        break;
      case 9:
        this.goToConsultBySiteMexico();
        break;
      case 10:
        this.goToReports();
        break;
      case 11:
        this.goToExchange();
        break;
      case 12:
        this.goToMultipleVinConsult();
        break;
      case 13:
        this.goToConsultByDealer();
        break;
      case 14:
        this.logout();
        break;
      case 15:
        this.goToMatrizEstatusEquipo();
        break;
      case 16:
        this.goToProgramacionCarga();
        break;
      case 17:
        this.goToAlertasPage();
        break;
      case 20:
        this.goToShipmentRequest();
        break;
      case 21:
        this.goToConsultaDeTractor();
        break;
      case 22:
        this.goToInvestorRelationsPage();
        break;
      case 23:
        this.goToAssetsManagerPage();
        break;
      case 24:
        this.goToRateTrip();
        break;
      case 25:
        this.goToVesselTravelList();
        break;
      case 60:
        this.goToUserPermissions();
        break;
      case 100:
        this.goToWebFrame();
        break;
    }
  }

  getActiveMenuItem(viewInstance: string) {
    // console.log('getActiveMenuItem: ', viewInstance);
    if (viewInstance.includes('/home')) {
      this.activeMenuItem = "home-item";
    }
    if (viewInstance.includes('/dashboard/')) {
      this.activeMenuItem = "dasboard-item";
    }
    if (viewInstance.includes('/dashboard_bsc')) {
      this.activeMenuItem = "dashboard-bsc-item";
    }
    if (viewInstance.includes('/tracking')) {
      this.activeMenuItem = "tracking-map-item";
    }
    if (viewInstance.includes('/travel/list')) {
      // travelType 1 Travel
      // travelType 3 Ratings
      this.activeMenuItem = "travel-list-item";
    }
    if (viewInstance.includes('/travel/list/vessel')) {
      // travelType 1 Travel
      // travelType 3 Ratings
      this.activeMenuItem = "vessel-travel-list-item";
    }
    if (viewInstance.includes('/exchange')) {
      this.activeMenuItem = "exchange-item";
    }
    if (viewInstance.includes('/shipment-request')) {
      this.activeMenuItem = "shipment-request-item";
    }
    if (viewInstance.includes('/report-menu')) {
      this.activeMenuItem = "resport-list-item";
    }
    if (viewInstance.includes('/consulta/by_site_mexico')) {
      this.activeMenuItem = "consulta-cd-item";
    }
    if (viewInstance.includes('/vehicle/multiple')) {
      this.activeMenuItem = "consulta-vin-item";
    }
    if (viewInstance.includes('/equipment')) {
      this.activeMenuItem = "consulta-tractor-item";
    }
    if (viewInstance.includes('/equipment/site')) {
      this.activeMenuItem = "matriz-estatus-item";
    }
    if (viewInstance.includes('/equipment/schedule')) {
      this.activeMenuItem = "programacion-carga-item";
    }
    if (viewInstance.includes('/alerts')) {
      this.activeMenuItem = "alarmas-item";
    }
    if (viewInstance.includes('/config')) {
      this.activeMenuItem = "configuracion-item";
    }
    if (viewInstance.includes('/rate-trip')) {
      this.activeMenuItem = "rate-trip-item";
    }
    if (viewInstance.includes('/investor-relations')) {
      this.activeMenuItem = "investor-relations-item";
    }
    if (viewInstance.includes('/assets-manager')) {
      this.activeMenuItem = "assets-manager-item";
    }
    if (viewInstance.includes('/permissions')) {
      this.activeMenuItem = "permisos-item";
    }
  }

  initializeMenu() {
    this.storage.get('userMenu').then((data: any) => {
      if (data.UserProfile != 1 && data.UserProfile && this.isAuth) {
        //this.showConsultaCD = true;
        this.showConsultBySite = true;
        this.showConfig = true;
        this.showHome = false;
        this.router.navigate(['/consulta/by_site_mexico'], { replaceUrl: true });
      } else if (data.Preferencias && this.isAuth) {
        this.showHome = true;
        for (const item of data.Preferencias) {
          switch (item.Id_Preferencia) {
            case '1':
              this.showTrackingMap = true;
              break;
            case '2':
              //notifications center
              break;
            case '3':
              this.showTravelList = true;
              break;
            case '4':
              this.showScore = true;
              break;
            case '5':
              this.showClaims = true;
              break;
            case '6':
              this.showConfig = true;
              break;
            case '7':
              //help
              break;
            case '8':
              this.showSingleQueryVin = true;
              break;
            case '9':
              this.showConsultBySite = true;
              break;
            case '10':
              this.showReport = true;
              break;
            case '11':
              this.showExchange = true;
              break;
            case '12':
              this.showMultipleVinConsult = true;
              break;
            case '13':
              this.showConsultByDealer = true;
              break;
            case '15':
              this.showMatrizEstatusEquipo = true;
              break;
            case '16':
              this.showProgramacionCarga = true;
              break;
            case '17':
              this.showAlertasPage = true;
              break;
            case '20':
              this.showShipmentRequest = true;
              break;
            case '21':
              this.showConsultaDeTractor = true;
              break;
            case '22':
              this.showInvestorRelations = true;
              break;
            default:
              break;
          }
        }
        this.router.navigate(['/home'], { replaceUrl: true });
      } else {
        this.goToLoginPage();
      }
    });
  }

  goToHome(dashboard: any = null) {
    let body = null;
    if (dashboard) {
      this.router.navigate(['/home/' + dashboard], { replaceUrl: true });
    } else {
      this.router.navigate(['/home'], { replaceUrl: true });
    }
  }

  goToDashboardFinanciero() {
    this.router.navigate(['/dashboard/financiero'], { replaceUrl: true });
  }

  goToDashboardOperativo() {
    this.router.navigate(['/dashboard/operativo'], { replaceUrl: true });
  }

  goToDashboardAMDF() {
    this.router.navigate(['/dashboard/amdf'], { replaceUrl: true });
  }

  goToDashboardMazda() {
    this.router.navigate(['/dashboard/mazda'], { replaceUrl: true });
  }

  goToDashboardBMW() {
    this.router.navigate(['/dashboard/bmw'], { replaceUrl: true });
  }

  goToClaims() {
    this.router.navigate(['/claims'], { replaceUrl: true });
  }

  goToConfig() {
    this.router.navigate(['/config'], { replaceUrl: true });
  }

  goToUserPermissions() {
    this.router.navigate(['/permissions'], { replaceUrl: true });
  }

  goToWebFrame() {
    this.router.navigate(['/quantum/fleet'], { replaceUrl: true });
  }

  goToVesselTravelList() {
    const routeParams = { // Se genera un objeto con los parametros a transmitir a la siguiente route
      routeId: 'transit', // 1.-En Transito - (transit), 2.-Calificado - (scored), 3.-Entregado - (delivered)
      travelType: 1,
    };

    // this.dataService.setData(routeParams.routeId, routeParams); // Con ayuda del provider DataService asignamos el objeto parametros a un iD, para de esta manera poder recibirlo en la siguiente ruta/pantalla
    this.router.navigate(['/travel/list/vessel'], { replaceUrl: true });
  }

  goToTravelList() {
    const routeParams = { // Se genera un objeto con los parametros a transmitir a la siguiente route
      routeId: 'transit', // 1.-En Transito - (transit), 2.-Calificado - (scored), 3.-Entregado - (delivered)
      travelType: 1,
    };

    this.dataService.setData(routeParams.routeId, routeParams); // Con ayuda del provider DataService asignamos el objeto parametros a un iD, para de esta manera poder recibirlo en la siguiente ruta/pantalla
    this.router.navigate(['/travel/list/' + routeParams.routeId], { replaceUrl: true });
  }

  goToTrackingMap() {
    this.router.navigate(['/tracking'], { replaceUrl: true });
  }

  goToScore() {
    const routeParams = { // Se genera un objeto con los parametros a transmitir a la siguiente route
      routeId: 'delivered', // 1.-En Transito - (transit), 2.-Calificado - (scored), 3.-Entregado - (delivered)
      travelType: 3,
    };

    this.dataService.setData(routeParams.routeId, routeParams); // Con ayuda del provider DataService asignamos el objeto parametros a un iD, para de esta manera poder recibirlo en la siguiente ruta/pantalla
    this.router.navigate(['/travel/list/' + routeParams.routeId], { replaceUrl: true });
  }

  // gotToConsultaCD() {
  // this.router.navigate(['/consult/site'], { replaceUrl: true });
  // }

  goToReports() {
    this.router.navigate(['/report/menu'], { replaceUrl: true });
  }

  goToSingleQueryVin() {
    this.router.navigate(['/vehicle/single'], { replaceUrl: true });
  }

  goToMultipleVinConsult() {
    this.router.navigate(['/vehicle/multiple'], { replaceUrl: true });
  }

  goToConsultByDealer() {
    this.router.navigate(['/consult/dealer'], { replaceUrl: true });
  }

  goToConsultBySiteMexico() {
    this.router.navigate(['/consulta/by_site_mexico'], { replaceUrl: true });
  }

  goToExchange() {
    this.router.navigate(['/exchange'], { replaceUrl: true });
  }

  goToMatrizEstatusEquipo() {
    this.router.navigate(['/equipment/site'], { replaceUrl: true });
  }

  goToProgramacionCarga() {
    this.router.navigate(['/equipment/schedule'], { replaceUrl: true });
  }

  goToAlertasPage() {
    this.router.navigate(['/alerts'], { replaceUrl: true });
  }

  goToShipmentRequest() {
    this.router.navigate(['/shipment-request'], { replaceUrl: true });
  }

  goToConsultaDeTractor() {
    this.router.navigate(['/equipment'], { replaceUrl: true });
  }

  goToInvestorRelationsPage() {
    this.router.navigate(['/investor-relations'], { replaceUrl: true });
  }

  goToAssetsManagerPage() {
    this.router.navigate(['/assets-manager'], { replaceUrl: true });
  }

  goToDashboardBSCCPVPage() {
    this.router.navigate(['/dashboard_bsc/cpv'], { replaceUrl: true });
  }

  goToDashboardBSCCSIPage() {
    this.router.navigate(['/dashboard_bsc/csi'], { replaceUrl: true });
  }

  goToDashboardBSCCSIDPage() {
    this.router.navigate(['/dashboard_bsc/csid'], { replaceUrl: true });
  }

  goToDashboardBSCCSIXPage() {
    this.router.navigate(['/dashboard_bsc/csix'], { replaceUrl: true });
  }

  goToDashboardBSCSunusPage() {
    this.router.navigate(['/dashboard_bsc/sunus'], { replaceUrl: true });
  }

  goToRateTrip() {
    this.router.navigate(['/rate-trip'], { replaceUrl: true });
  }

  /**
   * @name updateAppRequired
   * @desc Callback para mostrar una alerta solicitando que es necesario actualizar la app porque se cuenta con una version no soportada.
   */
  private async updateAppRequired() {
    const titulosAlerta = this.translate.instant('Etiqueta._Actualizar_APP');
    const mensajeAlerta = this.translate.instant('Mensaje._Actualizar_APP', { storeName: this.appStoreName });
    const aceptarButonAlerta = this.translate.instant('Boton._Actualizar_APP', { storeName: this.appStoreName });

    this.versionCheckServ.alertUpdateAppRequired(titulosAlerta, mensajeAlerta, aceptarButonAlerta);
  }

  logout() {
    this.storage.get('QuantumLogin:isActive').then((isTrue: boolean) => {
      console.log('isQuantumLogin: ', isTrue);
      let isQuantumLogin: boolean = isTrue;


      if (isQuantumLogin) {
        this.loginProvider.promptLogout().then((logoutConfirmed: boolean) => {
          if (logoutConfirmed) {
            this.sessionProvider.resetAll();
            this.goToLoginPage();
          }
        });

      } else {
        const logoutURI: string = 'https://login.microsoftonline.com/3e75ecfc-a7f2-4746-8905-5ea8c633e132/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%2Fdev.quantum.csigroup.com.mx%2Flogin%2Flogout';
        // const logoutURI: string = 'https://login.microsoftonline.com/3e75ecfc-a7f2-4746-8905-5ea8c633e132/oauth2/v2.0/logout?post_logout_redirect_uri=http%3A%2F%2Flocalhost%3A8100%2Flogin%2Flogout';

        if (CapacitorUtilsService.isApp) {
          // window.location.replace(result);
          this.capacitorService.goToLaunchBrowser(logoutURI);
        } else {
          window.location.replace(logoutURI);
        }
      }

    });

  }
}
